.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.6);
}
.fastEmail-exitButton_website {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 10px !important;
  right: 10px !important;
  border: none !important;
  background-color: white !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.fastEmail-preview_website {
  border-radius: 5px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
  background-color: white !important;
  font-family: sans-serif !important;
  font-size: 14px !important;
  text-transform: capitalize;
  width: 800px;
  height: 500px;
  z-index: 2147483646;
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fastEmail-previewTitle_website {
  display: flex;
  justify-content: center;
  align-items: center;
  /*border:1px solid black;
 */
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: sans-serif !important;
  font-size: 14px !important;
  background: #635afe;
  color: white !important;
  height: 50px !important;
}

.fastEmail-previewContent_website {
  flex: 3;
  border-top: none;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 1rem 1.5rem;
  font-family: sans-serif !important;
  font-size: 14px !important;
  background: none;
  overflow: auto;
  color: black !important;
  line-height: 1.3rem !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #635afe;
}

input:focus + .slider {
  box-shadow: 0 0 1px #635afe;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
